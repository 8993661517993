@import url('https://fonts.googleapis.com/css2?family=Cabin:wght@400;500;700&display=swap');

body{
    box-sizing: border-box;
    margin: 0;
}
a{
    text-decoration: none;
}

.Header{
    display: flex;
    flex-direction: column;
    font-family: Cabin;
}
.Logo{
    background-image: url('../Media/general/banner-cabecera.jpg');
    background-repeat: no-repeat;
    background-size: cover;
    padding: 0 0 0 50px;
    margin: 0;
    height: 100px;
    display: flex;
    align-items: center;
}
.Menu{
    padding:  10px  0  10px 150px;
    background-color: #002856;
}
.menu_item{
    margin: 0 20px 0 30px;
    color: white;
    font-weight: bold;
    font-size: 16pt;
}
.Footer{
    display: flex;
    flex-direction: row;
    background-color: #002856;
    padding-left: 150px;
    margin-top: 10px;
}
.Column{
    flex-basis: 33%;
    padding-bottom: 50px;
}
.FooterTitle{
    padding-top: 50px;
    font-family: Cabin;
    font-size: 12pt;
    font-weight: bold;
    color: #FEDF14;
    padding-bottom: 5px;
}
.FooterSubTitle{
    padding-top: 10px;
    font-family: Cabin;
    font-size: 12pt;
    color: #FEDF14;
}
.FooterDetail{
    font-family: Cabin;
    font-size: 12pt;
    color: white;
}
.footer_logo{
    display: flex;
    align-items: center;
    justify-content: right;
    padding-right: 66.41px;
}
.Contact{
    display: flex;
    flex-direction: row;
    margin-left: 218px;
}
.contacto_formulario{
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-top: 59.23px;
    flex-basis: 70%;
}
.Contact_Header{
    font-size: 38pt;
    font-family: 'Cabin';
    text-align: left;
    font-weight: bold;
    color:#002856;
}
.Contact_SubHeader{
    text-align: left;
    color:#002856;
    font-size:23pt;
    font-family: 'Cabin';
    font-weight:500;
}
.contactColumnInfo{
    display: flex;
    flex-direction: column;
    flex-basis: 30%;
    align-items: center;
    padding-top: 80px;
}
.ContactTitle{
    padding-top: 20px;
    font-family: 'Cabin';
    font-weight: bold;
    color:#002856;
    font-size: 18pt;
}
.ContactSubTitle{
    padding-top: 10px;
    font-family: 'Cabin';
    font-weight: 500;
    color:#002856;
    font-size: 18pt;
}
.ContactDetail{
    font-family: 'Cabin';
    font-weight: 200;
    color:#002856;
    font-size: 14pt;
}
.formRow{
    display: flex;
    flex-direction: row;
}
.formField{
    flex-basis: 50%;
    margin: 10px;
}
.contacto_field{
    border: #002856 1px solid;
    border-radius: 0;
    width: 100%;
    height: 40px;
    font-size: 15pt;
    font-family: 'Cabin';
    color: #002856;
}
.textAreaContactoContainer{
    margin:10px;
}
.textAreaContacto{
    width: 100%;
    border: #002856 1px solid;
    border-radius: 0;
    resize: none;
    font-size: 15pt;
    font-family: 'Cabin';
    color: #002856;
}
.contact_buttonContainer{
    width: 100%;
    display: flex;
    justify-content: right;
}
.contact_button{
    background-color: #002856;
    border-radius: 0;
    color:#FEDF14;
    padding:10px;
    margin:10px;

}
.Home{
    display: flex;
    flex-direction: row;
}
.ContenedorEdiciones{
    background-color:  #E5E4E4;
    margin-top: 59px;
    margin-left: 67px;
    margin-right: 67px;
    margin-bottom: 60px;
    align-items: center;
   /** justify-content: center;**/
    display: flex;
    flex-direction: column;
    height: 620px;
    width: 778px;
    overflow:auto;

}
.ContenedorAds01{
    height: 444px;      
    margin-top: 18px;
    margin-right: 98px;
    margin-bottom: 116px;
    display: block;
    justify-content: right;
    align-items: center;
}
.ContenedorAds{
    width: 100%;
    height: 444px;    
    margin-top: 59px;
    margin-right: 98px;
    margin-bottom: 116px;
    display: flex;
    justify-content: right;
    align-items: center;
}
.ContenedorAdsInside{
    height: 444px;
    width: 219px;
    background-color:  #E5E4E4;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 90px;
    margin-top: 40px!important;
}
.Portada01{
    height: 350px;
}
.Portada{
    height: 350px;
    width: 172px;
}
.EdicionesTitle{
  /*  padding-top: 300px; */
    font-size: 16pt;
    font-weight: bold;
    font-family: 'Cabin';
    text-align: center;
}
.EdicionesPortadas{
    margin: 0;
    padding: 0;
}

.PortadaTitle{
    background-color: white;
    color: black;
    margin: 0;
    padding: 10px;
    font-size: 11pt;
    font-family: 'Cabin';
    font-weight: bold;
}

.Edicion01{
    display: flex;
    flex-direction: row;
}

.Edicion01Column1{
    display: flex;
    flex-direction: column;
    margin-left: 218px;
}

.EdicionBody{
    display: flex;
    flex-direction: row;
    background-color: #E5E4E4;
    width: 627px;
    height: 311px;
}
.EdicionHeader{
    padding: 0;
    margin-top: 59px;
    margin-bottom: 29px;
    padding-left: 20px;
    background-color: #002856;
    color:#FEDF14;
    width: 627px;
    height: 105px;
    display: flex;
    justify-content: center;
    align-content: center;
    flex-direction: column;
}
.EdicionInfo{
    font-size: 18pt;
    font-family: 'Cabin';
    font-weight: bold;
}
.EdicionDescrition{
    font-size: 10px;
    font-family: 'Cabin';
    text-align: justify;
}
.commentTextArea{
    overflow: hidden;
    width: 270px;
    height: 57px;
    font-size: 10px;
    resize: none;
    border:  #002856 solid 1px;
}
.EdicionesColumn1{
    display: flex;
    justify-content: center;
    align-content: center;
    padding:25px;
}
.EdicionesColumn2{
    padding: 25px 10px 0 0;
}
.BotonEnviarContainer{
    margin:0;
    padding: 0;
    margin-left: 240px;
}
.commentContainer{
    margin: 0;
    padding: 0;
    margin-top: 20px;
}
.BotonEnviar{
    background-color: #002856;
    color: #FEDF14;
    width: 34px;
    font-size: 8px;
    margin: 0;
    padding: 0;
}
.BotonDescargar{
    background-color: #002856;
    color: #FEDF14;
    margin: 10px;
    width: 120px;
    height: 27px;
    font-size: 10px;
    font-weight: bold;
    font-family: 'Cabin';
}
.BotonVer{
    background-color: #002856;
    color: #FEDF14;
    margin: 10px;
    width: 120px;
    height: 27px;
    font-size: 10px;
    font-weight: bold;
    font-family: 'Cabin';
}
.pdfObject{
    width: 1010px;
    height: 665px;
}

.Edicion01Pdf{
    height: 1009px;
    background-image: url('../Media/fondo.jpg');
    background-repeat: no-repeat;
    background-size: cover;
    padding-left: 38px;
    justify-content: center;
    scroll-behavior: auto;
    overflow: auto;
}
.logoEdicion01{
    height: 127px;
    display: flex;
    align-items: center;
}

.publuuContainer{
    display: flex;
    justify-content: center;
}
.publuuContainerBackground{
    width: 1115px;
    height: 764px;
    background-color: #E5E4E4;
    display: flex;
    justify-content: center;
    align-items: center;
    
}
.Who{
    display: flex;
    flex-direction: row;
}
.WhoMainContainer{
    margin-top: 59px;
    margin-left: 218px;
    display: flex;
    flex-direction: column;
}
.WhoTitle{
    font-family: 'Cabin';
    font-size: 38pt;
    color:#002856;
}
.WhoBody{
    font-family: 'Cabin';
    font-size: 13pt;
    margin-right: 100px;
}

@media(max-width:900px) {
    .Header{
        width: 414px;
    }
    .Logo{
        height: 56px;
        padding:0;
        justify-content: center;
    }
    .Header_logo{
        width: 261px;
        height:30px;
    }
    .Menu{
        margin: 0;
        padding: 0;
        display:flex;
        flex-direction: row;
        justify-content: center;
    }
    .menu_item{
        font-size: 9pt;
    }
    .Home{
        flex-direction: column;
        align-items: center;
        width: 414px;
    }
    .ContenedorEdiciones{
        width: 368px;
        height: 280px;
        margin-top: 30px;
        padding-top: 10px;
        padding-bottom: 15px;
        margin-bottom: 15px;
    }
    .Portada01{
        width: 132px;
        height: 174px;
    }
    .EdicionesPortadas{
        display: flex;
        justify-content: center;
    }
    .EdicionesTitle{
        font-size: 10pt;
        padding-bottom: 10px;
    }
    .PortadaTitle{
        margin: 10px;
        font-size: 8pt;
    }
    .Portada{
        width: 334px;
        height: 108px;
    }
    .ContenedorAds01{
        height: auto;
        margin: 0;
        padding: 0;
        justify-content:center;
        width: 368px;
        height: 325px;
        background-color: #E5E4E4;
        margin-bottom: 20px;
        display: flex;
        flex-direction: column;
    }
    .Footer{
        width: 414px;
        padding:0;
    }
    .Column{
        flex-basis: 50%;
        padding: 5px;
        padding-left: 20px;
    }
    .FooterTitle{
        padding-top: 10px;
        font-size: 8pt;
    }
    .FooterSubTitle{
        padding-top: 5px;
        font-size: 8pt;
    }
    .FooterDetail{
        font-size: 8pt;
    }
    .footer_logo{
        justify-content: left;
        margin-top: 10px;
        padding: 0;
    }
    .Edicion01{
        flex-direction: column;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 414px;
    }
    .EdicionHeader{
        width: 369px;
        height: 60px;
        margin-top: 20px;
        padding-left: 0px;
        margin-bottom: 15px;
    }
    .Edicion01Column1{
        margin:0px;
        justify-content: center;
        width: 368px;
    }
    .EdicionLogo{
        font-family: 'Cabin';
        font-size: 16px;
        font-weight: bold;
        padding-left: 20px;
    }
    .EdicionInfo{
        font-size: 11px;
        padding-top: 5px;
        padding-left: 20px;
    }
    .EdicionBody{
        width: 368px;
        height: 174px;
    }
    .PortadaEdiciones{
        width: 96px;
        height: 128px;
    }
    .EdicionDescrition{
        font-size: 7px;
    }
    .EdicionesColumn1{
        padding: 5px;
    }
    .EdicionesColumn2 {
        padding: 5px 2px 0 0;
    }
    .commentTextArea{
        width: 248px;
        height: 55px;
    }
    .commentContainer {
        margin-top: 5px;
    }
    .BotonEnviar{
        height: 11px;
        width: 46px;
        font-size: 7px;
    }
    .BotonEnviarContainer {
        margin: 0;
        padding: 0;
        margin-left: 207px;
    }
    .BotonesContainer{
        display: flex;
        flex-direction: row;
        background-color: #E5E4E4;
    }
    .MainButton{
        height: 21px;
        width: 93px;
        font-size: 8px;
    }
    .ContenedorAds{
        margin: 0;
        height: auto;
        justify-content: center;
        margin-bottom: 10px;
        margin-top: 10px;
    }
    .ContenedorAdsInside{
        height: 129px;
        width: 368px;
        margin:0;
    }
    .logoEdicion01Img{
        width: 262px;
        height: 30px;
        margin: 20px;
    }
    .logoEdicion01{
        height: auto;
        justify-content: left;
        width: 368px;
    }
    .Edicion01Pdf{
        width: 414px;
        height: auto;
        padding: 0;
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-content: center;
    }
    .publuuContainer{
        padding-bottom: 20px;
    }
    .publuuContainerBackground{
        width: 363px;
        height: 456px;
    }
    .pdfObject{
        width: 301px;
        height: 397px;
    }
    .Who{
        width: 414px;
        flex-direction: column;
        align-items: center
    }
    .WhoMainContainer{
        width: 414px;
        margin: 0;
        justify-content: center;
        align-items: center
    }
    .WhoTitle{
        font-size: 23pt;
        text-align: center;
        width: 368px;
    }
    .WhoBody{
        font-size: 9pt;
        text-align: justify;
        width: 368px;
        margin: auto;
    }
    .Contact{
        width: 414px;
        margin:0;
        justify-content: center;
    }
    .contacto_formulario{
        flex-basis: auto;
        margin: 0;
        padding: 0;
        width: 368px;
        margin-bottom: 10px;
    }
    .Contact_Header{
        font-size: 23pt;
        text-align: center;
    }
    .Contact_SubHeader{
        font-size: 15pt;
        text-align: center;
    }
    .contacto_field{
        font-size: 10pt;
    }
    .contact_button{
        font-size: 14px;
        height: 28px;
        width: 56px;
        margin:0;
        padding: 0;
    }
    
}   

.pdfContainer{
    width: 100%;
    height: 100%;
}
.pdf{
    width: 100%;
    height: 600px;
}
#mensajeEspera{
    margin: 20px;
    font-weight: bold;
    font-size: 20px;
    color: rgb(207, 207, 207);
    text-align: center;
}
.videoContainer{
    width: 173px;
    display: flex;
    justify-content: center;
    background-color: #E5E4E4;
    padding: 23px;
}
.videoObject{
    width:100%;
}

.linkVideo{
    text-align: center;
    color: black;
    font-family: 'Cabin';
}